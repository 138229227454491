
textarea.rs-input {
  resize: none !important;
}


.rs-avatar > .rs-avatar-image {
  object-fit: cover;
}

.rs-popover {
  padding-right: 0;
  padding-left: 0;
}