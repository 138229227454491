
.toastsContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 8px;
  top: 68px;
  right: 10px;
  max-height: 100vh;
  max-width: 480px;
  width: 100%;
  z-index: 99999;
  box-sizing: border-box;
}

.toast {
  box-sizing: border-box;
  width: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border: 1px solid green;
  background-color: #EDFAE1;

  button {
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .toastGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;

      svg {
          min-width: 16px;
      }

      .toastgroupContent {
          margin-right: 12px;
      }
  }

  &.toastError {
      border: 1px solid red;
      background-color: #FDE9EF;
  }

  &.toastInfo {
    border: 1px solid #61affe;
    background-color: #eff7fe;
  };

  .toastTitle {
      font-weight: 600;
  }

}